<template>
  <div style="padding-top:10px;">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane
        v-for="(item, index) in tabList"
        :key="index"
        :label="item.label"
        :name="item.name"
      >
        <div class="header">
          <el-row type="flex" justify="space-between" :gutter="20">
            <el-col :span="6">
              <div class="grid-content">
                <span class="left">账户名称</span>
                <el-input
                  class="right"
                  size="small"
                  v-model.trim="accountName"
                  placeholder="请输入账户名称"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content">
                <span class="left">证件号码</span>
                <el-input
                  class="right"
                  size="small"
                  v-model.trim="credentialNo"
                  placeholder="请输入证件号码"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content">
                <span class="left">支付账户号</span>
                <el-input
                  class="right"
                  size="small"
                  v-model.trim="accountNo"
                  placeholder="请输入支付账户号"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="6" v-if="activeName === 'online'">
              <div class="grid-content">
                <span class="left">渠道</span>
                <el-select
                  class="right"
                  size="small"
                  v-model="channelName"
                  placeholder="请选择"
                  @change="channelRes"
                >
                  <el-option
                    v-for="item in channelList"
                    :key="item.channel"
                    :label="item.channelName"
                    :value="item.channel"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between" :gutter="20">
            <el-col :span="6" v-if="activeName === 'online'">
              <div class="grid-content">
                <span class="left">用户类型</span>
                <el-select
                  class="right"
                  size="small"
                  v-model="userType"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in userTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="6" v-if="activeName === 'online'">
              <div class="grid-content">
                <span class="left">账户类型</span>
                <el-select
                  class="right"
                  size="small"
                  v-model="accountType"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in accountTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="6" v-if="activeName === 'agent'">
              <div class="grid-content">
                <span class="left">用户类型</span>
                <el-select
                  class="right"
                  size="small"
                  v-model="userType"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in userTypeAgentList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="6" v-if="activeName !== 'agent'">
              <div class="grid-content">
                <span class="left">所属平台</span>
                <el-select
                  class="right"
                  v-model="isvId"
                  size="small"
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入所属平台"
                  :remote-method="remoteMethod"
                  :loading="inputLoading"
                >
                  <el-option
                    v-for="item in platList"
                    :key="item.isvId"
                    :label="item.isvName"
                    :value="item.isvId"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="btn-content">
                <el-button type="primary" size="medium" @click="handleQuery"
                  >查询</el-button
                >
                <el-button size="medium" @click="handleClear">清空</el-button>
              </div>
            </el-col>
          </el-row>
        </div>
        <cesTable
          :isButton="false"
          :isSelection="false"
          :isIndex="true"
          :isPagination="true"
          :tableData="tableData"
          :tableCols="
            activeName === 'online'
              ? tableCols
              : activeName === 'unified'
              ? tableCols2
              : tableCols3
          "
          :pagination="pagination"
          :key="keyDate"
          :loading="loading"
          @hadnleOperate="handleOperate"
          @handleDetail="handleDetail"
          @handleCurrentChange="handleCurrentChange"
          @handleSizeChange="handleSizeChange"
        ></cesTable>
      </el-tab-pane>
    </el-tabs>
    <accountDetail
      :dialogAccountDetailFormVisible.sync="dialogAccountDetailFormVisible"
      :accountDetailId="accountDetailId"
      :userType="currentUserType"
      :activeName="activeName"
    ></accountDetail>

    <el-dialog
      title="编辑账户信息"
      :visible.sync="dialogFormVisible"
      width="600px"
    >
      <el-form
        :model="dialogForm"
        :rules="rules"
        ref="dialogForm"
        label-width="150px"
      >
        <el-form-item label="在线账户号" prop="accountNo">
          <el-input v-model="dialogForm.accountNo" disabled></el-input>
        </el-form-item>
        <el-form-item label="账户名称" prop="accountName">
          <el-input v-model="dialogForm.accountName" disabled></el-input>
        </el-form-item>
        <el-form-item
          label="营业执照"
          prop="licensePhoto"
          v-if="!isPersonAgent"
        >
          <uploader
            :src="dialogForm.licensePhoto"
            @upload-success="uploadSuccess($event, 'licensePhoto')"
          ></uploader>
        </el-form-item>
        <el-form-item label="证件号码" prop="credentialNo">
          <el-input v-model="dialogForm.credentialNo" disabled></el-input>
        </el-form-item>
        <el-form-item
          label="法人代表姓名"
          prop="principalName"
          v-if="!isPersonAgent"
        >
          <el-input v-model="dialogForm.principalName"></el-input>
        </el-form-item>
        <el-form-item
          label="法人代表身份证号"
          prop="principalIdCard"
          v-if="!isPersonAgent"
        >
          <el-input v-model="dialogForm.principalIdCard"></el-input>
        </el-form-item>
        <el-form-item
          label="身份证照片"
          prop="principalIdCardPhotoA"
          v-if="!isPersonAgent"
        >
          <div style="display: flex;">
            <uploader
              :src="dialogForm.principalIdCardPhotoA"
              @upload-success="uploadSuccess($event, 'principalIdCardPhotoA')"
            ></uploader>
            <div style="margin-left:16px">
              <uploader
                :src="dialogForm.principalIdCardPhotoB"
                @upload-success="uploadSuccess($event, 'principalIdCardPhotoB')"
              ></uploader>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="联系人手机" prop="contractMobile">
          <el-input v-model="dialogForm.contractMobile"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogClose('dialogForm')">取 消</el-button>
        <el-button type="primary" @click="dialogSave('dialogForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import cesTable from '@/components/table'
import uploader from '@/components/uploader'
import { CHANNELLIST } from '@/assets/js/config'
import {
  accountManageList,
  platManageList,
  accountManageUnifiedList,
  accountDetailQuery,
  accountDetailUpdate,
  accountAgentList,
  accountAgentUnbind,
} from '@/request/api'
import accountDetail from './components/accountDetail'
export default {
  components: {
    cesTable,
    accountDetail,
    uploader,
  },
  data() {
    return {
      activeName: 'online',
      dialogAccountDetailFormVisible: false,
      accountDetailId: null,
      currentUserType: null,
      accountName: '',
      credentialNo: '',
      accountNo: '',
      isvId: '',
      inputLoading: false,
      dialogFormVisible: false,
      isPersonAgent: false,
      platList: [],
      channel: '',
      channelName: '',
      channelList: CHANNELLIST,
      accountType: '',
      // 0:开户中，1：已开户；2：开户失败；3：已禁用
      //   在线账户、记账账户、一般结算户（工行平台账户）
      accountTypeList: [
        { label: '在线账户', value: 2 },
        { label: '手续费账户', value: 10 },
        { label: '一般结算户', value: 1 },
        { label: '车队长', value: 4 },
      ],
      // accountTypeName: '',
      userTypeList: [
        { label: '企业', value: 3 },
        { label: '个人', value: 1 },
      ],
      userTypeAgentList: [
        { label: '企业', value: 3 },
        { label: '个体工商户', value: 4 },
        { label: '个人', value: 1 },
      ],
      userType: '',
      tabList: [
        { label: '渠道账户', name: 'online' },
        { label: '统一账户', name: 'unified' },
        { label: '代理人账户', name: 'agent' },
      ],
      keyDate: '',
      tableData: [],
      dialogForm: {
        accountNo: '',
        accountName: '',
        licensePhoto: '',
        credentialNo: '',
        principalName: '',
        principalIdCard: '',
        principalIdCardPhotoA: '',
        principalIdCardPhotoB: '',
      },
      tableCols: [
        {
          label: '操作',
          type: 'Button',
          width: '50',
          permission: '/account/detail/update',
          op: [{ text: '编辑', class: 'handleModify' }],
        },
        { label: '账户名称', prop: 'accountName' },
        { label: '证件号码', prop: 'credentialNo' },
        { label: '支付账户号', prop: 'accountNo' },
        { label: '实际可用余额', prop: 'actualUsableAmt' },
        { label: '冻结金额', prop: 'frozenAmt' },
        { label: '渠道', prop: 'institutionCodeName' },
        { label: '账户类型', prop: 'accountTypeName' },
        { label: '接入方账户号', prop: 'userAccountNo' },
        {
          label: '账户详情',
          prop: 'openAccount',
          type: 'Detail',
          special: '详情',
          permission: '/account/balance/detail',
        },
        { label: '所属平台', prop: 'isvName' },
      ],
      tableCols2: [
        {
          label: '操作',
          type: 'Button',
          width: '50',
          permission: '/account/detail/update',
          op: [{ text: '编辑', class: 'handleModify' }],
        },
        { label: '账户名称', prop: 'accountName' },
        { label: '证件号码', prop: 'credentialNo' },
        { label: '支付账户号', prop: 'accountNo' },
        { label: '实际可用余额', prop: 'actualUsableAmt' },
        { label: '冻结金额', prop: 'frozenAmt' },
        { label: '账户类型', prop: 'accountTypeName' },
        { label: '接入方账户号', prop: 'userAccountNo' },
        {
          label: '账户详情',
          prop: 'openAccount',
          type: 'Detail',
          special: '详情',
          permission: '/account/balance/detail',
        },
        { label: '所属平台', prop: 'isvName' },
      ],
      tableCols3: [
        {
          label: '操作',
          type: 'Button',
          width: '50',
          permission: '/account/detail/update',
          op: [
            { text: '编辑', class: 'handleModify' },
            { text: '解绑卡', class: 'handleUnbind' },
          ],
        },
        { label: '账户名称', prop: 'accountName' },
        { label: '支付账户号', prop: 'accountNo' },
        { label: '证件号码', prop: 'credentialNo' },
        { label: '账户类型', prop: 'accountTypeName' },
        { label: '接入方账户号', prop: 'userAccountNo' },
        { label: '实际可用余额', prop: 'actualUsableAmt' },
        { label: '冻结金额', prop: 'frozenAmt' },
        { label: '绑定手机号', prop: 'contractMobile' },
        { label: '账户生效时间', prop: 'createTime' },
        { label: '绑定银行卡号', prop: 'bindAccountNo' },
        { label: '绑定银行', prop: 'bindBankName' },
      ],
      pagination: {
        pageSize: 50,
        pageNum: 1,
        total: 0,
      },
      loading: false,
      rules: {
        contractMobile: [
          { required: true, message: '请输入', trigger: 'blur' },
          {
            pattern: /^(1+\d{10})$/,
            message: '请输入正确的手机号码',
            trigger: 'blur',
          },
        ],
        principalName: [{ required: true, message: '请输入', trigger: 'blur' }],
        licensePhoto: [
          { required: true, message: '不能为空', trigger: 'change' },
        ],
        principalIdCardPhotoA: [
          { required: true, message: '不能为空', trigger: 'change' },
        ],
        principalIdCard: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
      },
    }
  },
  mounted() {
    this.queryList()
  },
  methods: {
    async remoteMethod(query) {
      try {
        const p = {
          page: {
            current: 1,
            size: 20,
          },
          isvName: query,
        }
        this.inputLoading = true
        const res = await platManageList(p)
        this.inputLoading = false
        if (res.status === 200 && res.data) {
          this.platList = res.data.items || []
        }
      } catch (error) {
        console.log(error)
      }
    },
    async queryList() {
      try {
        const p = {
          page: {
            current: this.pagination.pageNum,
            size: this.pagination.pageSize,
          },
          accountName: this.accountName,
          credentialNo: this.credentialNo,
          accountNo: this.accountNo,
          channel: this.channel,
          subtype: this.accountType,
          isvId: this.isvId,
          userType: this.userType,
        }
        this.loading = true

        const res =
          this.activeName === 'online'
            ? await accountManageList(p)
            : this.activeName === 'unified'
            ? await accountManageUnifiedList(p)
            : await accountAgentList(p)
        this.loading = false
        if (res.status === 200 && res.data) {
          this.tableData = res.data.items
          if (res.data.total && p.page.current === 1) {
            this.pagination.total = res.data.total
          }
        } else {
          this.$message.error(res.message)
          this.tableData = []
          this.pagination.total = 0
        }
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    async handleOperate(a, row, c) {
      if (c.class == 'handleModify') {
        if (
          this.activeName === 'online' &&
          row.category !== 1 &&
          row.accountType !== 2
        ) {
          return this.$message.error('仅支持平台主账户修改')
        }
        if (
          this.activeName === 'unified' &&
          (row.userType !== 3 || row.userType !== 4) &&
          row.accountType !== 3
        ) {
          return this.$message.error('仅支持托运方企业账户修改')
        }
        const res = await accountDetailQuery({
          accountNo: row.accountNo,
          type:
            this.activeName === 'online'
              ? 1
              : this.activeName === 'unified'
              ? 2
              : 3,
          isvId: row.isvId,
        })
        if (res.status !== 200) {
          return this.$message.error(res.message || '服务错误，请重试')
        }
        this.dialogForm = { ...res.data }
        this.isPersonAgent = this.activeName === 'agent' && row.userType === 1
        this.dialogFormVisible = true
      } else if (c.class == 'handleUnbind') {
        //解绑卡
        if (row.userType !== 4) {
          return this.$message.error('仅支持个体工商户代理人账户解绑卡')
        }
        this.$confirm(
          '请确认是否解除该用户的绑定卡,解绑后用户可用过账户管理重新绑定,请谨慎操作',
          '解绑卡',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
          }
        ).then(async () => {
          const p = { accountNo: row.accountNo, credentialNo: row.credentialNo }
          const res = await accountAgentUnbind(p)
          if (res.status === 200) {
            this.$message({
              type: 'success',
              message: '解绑成功!',
            })
            this.queryList()
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    uploadSuccess(url, name) {
      this.dialogForm[name] = url
    },
    handleClick() {
      this.keyDate = new Date().getTime()
      this.handleClear()
      this.handleQuery()
    },
    handleQuery() {
      this.pagination.pageNum = 1
      this.queryList()
    },
    handleClear() {
      this.accountName = ''
      this.accountNo = ''
      this.channelName = ''
      this.channel = ''
      this.accountType = ''
      this.userType = ''
      this.isvId = ''
      this.credentialNo = ''
    },
    handleDetail(row) {
      this.accountDetailId = row.id
      this.currentUserType = row.userType
      this.dialogAccountDetailFormVisible = true
    },
    handleCurrentChange(val) {
      this.pagination.pageNum = val
      this.queryList()
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val
      this.queryList()
    },
    dialogClose() {
      this.$refs.dialogForm.resetFields()
      this.dialogFormVisible = false
    },
    dialogSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          accountDetailUpdate(this.dialogForm).then((res) => {
            if (res.status == 200) {
              this.$message({
                type: 'success',
                message: '修改成功',
              })
              this.queryList()
              this.dialogClose()
            } else {
              this.$message({
                message: res.message,
                type: 'error',
                center: 'true',
              })
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    channelRes(channel) {
      this.channel = channel
    },
  },
}
</script>

<style lang="stylus" scoped>
/deep/.el-tabs__item {
    width: 120px;
    text-align: center;
}

.header {
    padding:0 20px 10px 20px;
}

.el-row {
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
}

.grid-content {
    display: flex;
    align-items: center;
}

.left {
    margin-right: 20px;
    font-size: 14px;
}

.right {
    flex: 1;
}

.btn-content {
    display: flex;
    justify-content: flex-end;
}
</style>
