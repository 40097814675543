<template>
  <div>
    <el-upload
      class="avatar-uploader"
      action="/api/file/upload"
      name="filename"
      accept="image/png, image/jpeg"
      :show-file-list="false"
      :on-success="handleSuccess"
      :before-upload="beforeUpload"
    >
      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
</template>

<script>
export default {
  props: {
    src: { type: String, default: '' },
  },
  data() {
    return {
      imageUrl: '',
    }
  },
  watch: {
    src(v) {
      if (v) {
        this.imageUrl = v
      } else {
        this.imageUrl = ''
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.imageUrl = this.src || ''
    })
  },
  methods: {
    handleSuccess(res, file) {
      console.log(file)
      if (res.status === 200 && res.data.url) {
        this.imageUrl = res.data.url
        this.$emit('upload-success', res.data.url)
      } else {
        this.$message.error('图片上传失败')
      }
      // this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeUpload(file) {
      // const isJPG = file.type === 'image/jpeg'
      const isLimit = file.size / 1024 / 1024 < 5

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!')
      // }
      if (!isLimit) {
        this.$message.error('上传图片大小不能超过5MB!')
      }
      return isLimit
    },
  },
}
</script>

<style lang="stylus">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}
.avatar {
  width: 150px;
  height: 150px;
  display: block;
}
</style>
