<template>
  <div>
    <el-dialog
      title="账户详情"
      :visible.sync="dialogAccountDetailFormVisible"
      :before-close="close"
    >
      <el-form :model="childForm" label-position="right">
        <el-form-item label="账户名称" :label-width="formLabelWidth">
          <span class="item-right">{{ childForm.accountName || '-' }}</span>
        </el-form-item>
        <el-form-item label="证件号码" :label-width="formLabelWidth">
          <span class="item-right">{{ childForm.credentialNo || '-' }}</span>
        </el-form-item>
        <el-form-item label="可用余额" :label-width="formLabelWidth">
          <span class="item-right">{{ childForm.actualUsableAmt || '-' }}</span>
        </el-form-item>
        <el-form-item label="冻结金额" :label-width="formLabelWidth">
          <span class="item-right">{{ childForm.frozenAmt || '-' }}</span>
        </el-form-item>
        <el-form-item label="支付账户号" :label-width="formLabelWidth">
          <span class="item-right">{{ childForm.accountNo || '-' }}</span>
        </el-form-item>
        <el-form-item label="渠道账户号" :label-width="formLabelWidth">
          <span class="item-right">{{ childForm.bankAccountNo || '-' }}</span>
        </el-form-item>
        <el-form-item label="渠道" :label-width="formLabelWidth">
          <span class="item-right">{{
            childForm.institutionCodeName || '-'
          }}</span>
        </el-form-item>
        <el-form-item label="账户类型" :label-width="formLabelWidth">
          <span class="item-right">{{ childForm.accountTypeName || '-' }}</span>
        </el-form-item>
        <el-form-item label="绑定鉴权手机" :label-width="formLabelWidth">
          <span class="item-right">{{
            childForm.bindAccountMobile || '-'
          }}</span>
        </el-form-item>
        <el-form-item label="账户生效时间" :label-width="formLabelWidth">
          <span class="item-right">{{ childForm.usableTime || '-' }}</span>
        </el-form-item>
        <el-form-item label="所属平台" :label-width="formLabelWidth">
          <span class="item-right">{{ childForm.isvName || '-' }}</span>
        </el-form-item>
        <el-form-item label="绑定银行卡" :label-width="formLabelWidth">
          <span class="item-right">{{ childForm.bindAccountNo || '-' }}</span>
        </el-form-item>
        <el-form-item
          label="账户余额账单"
          v-if="
            childForm.institutionCode === 'antb' &&
              childForm.accountType === 2 &&
              userType === 3
          "
          :label-width="formLabelWidth"
        >
          <el-button type="text" @click="handleQuery(1)">查看</el-button>
        </el-form-item>
        <el-form-item
          label="账户交易明细"
          v-if="
            (childForm.institutionCode === 'antb' ||
              childForm.institutionCode === 'SPABANK') &&
              childForm.accountType === 2 &&
              userType === 3
          "
          :label-width="formLabelWidth"
        >
          <el-button
            type="text"
            @click="
              handleQuery(childForm.institutionCode === 'SPABANK' ? 3 : 2)
            "
            >查看</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      :title="popTitle"
      :visible.sync="popVisible"
      :before-close="closePop"
    >
      <el-form :model="childForm" label-position="right">
        <div class="grid-content">
          <div v-if="isDeal != 3">
            <span class="left">开始结束时间</span>
            <el-date-picker
              class="right"
              v-model="dateResult"
              type="daterange"
              size="small"
              value-format="yyyy-MM-dd"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </div>
          <div v-else>
            <span class="left">申请月份</span>
            <el-date-picker
              class="right"
              v-model="dateResult"
              type="month"
              value-format="yyyy-MM-dd"
              size="small"
              :picker-options="pickerOptions2"
              placeholder="选择月"
            >
            </el-date-picker>
          </div>
          <div class="btn-content">
            <el-button
              type="primary"
              size="medium"
              @click="handleApply"
              :loading="applyLoading"
              >{{ btnText }}</el-button
            >
          </div>
        </div>
        <section class="table-box">
          <h2 class="title">下载记录</h2>
          <el-table
            ref="multipleTable"
            :data="tableData"
            v-loading="loading"
            stripe
            height="400"
            style="width: 100%"
          >
            <el-table-column
              type="index"
              label="序号"
              width="60"
            ></el-table-column>
            <el-table-column prop="created" label="创建时间"></el-table-column>
            <el-table-column prop="created" label="开始结束时间">
              <template slot-scope="scope">
                <span v-if="scope.row.type !== 3"
                  >{{ scope.row.startDate }} 至 {{ scope.row.endDate }}</span
                >
                <span v-else>{{ scope.row.startDate.substr(0, 7) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  :underline="false"
                  style="font-size:14px;"
                  v-if="scope.row.state === 1"
                  @click="openDown(scope.row.receiptUrl)"
                  >下载回单</el-link
                >
                <span v-else>{{
                  scope.row.state === 2 ? '申请失败' : '申请中'
                }}</span>
                <el-link
                  v-if="scope.row.excelUrl"
                  type="primary"
                  :underline="false"
                  style="font-size:14px;margin-left:16px"
                  @click="openDown(scope.row.excelUrl)"
                  >下载Excel</el-link
                >
              </template>
            </el-table-column>
          </el-table>
          <section style="text-align:right;">
            <el-pagination
              style="justify-content: start;height: 100%;align-items: center;padding:8px;"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
              layout="total,sizes ,prev, pager, next,jumper"
              :page-size="pagination.pageSize"
              :current-page="pagination.pageNum"
              :total="pagination.total"
            ></el-pagination>
          </section>
        </section>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  accountDetail,
  listReceiptApi,
  applyReceiptApi,
  accountUnifiedDetail,
} from '@/request/api'
import { getDayBefore } from '@/assets/js/utils'
// import cesTable from '@/components/table'
export default {
  //   components: {
  //     cesTable,
  //   },
  props: {
    dialogAccountDetailFormVisible: { type: Boolean, default: false },
    accountDetailId: { type: Number, default: null },
    userType: { type: Number, default: null },
    activeName: { type: String, default: 'online' },
  },
  data() {
    return {
      formLabelWidth: '140px',
      childForm: {},
      popVisible: false,
      popTitle: '',
      btnText: '',
      dateResult: '',
      isDeal: false,
      keyDate: '',
      tableData: [],

      pagination: {
        pageSize: 50,
        pageNum: 1,
        total: 0,
      },
      loading: false,
      applyLoading: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > new Date(getDayBefore(1))
        },
      },
      pickerOptions2: {
        disabledDate(time) {
          const d = new Date()
          return time.getTime() > new Date(d.getFullYear(), d.getMonth(), 0)
        },
      },
    }
  },
  watch: {
    accountDetailId(newVue) {
      this.queryDetail(newVue)
    },
  },
  methods: {
    async queryDetail(newVue) {
      try {
        const p = { id: newVue }
        const res =
          this.activeName === 'online'
            ? await accountDetail(p)
            : await accountUnifiedDetail(p)
        if (res.status === 200 && res.data) {
          this.childForm = { ...res.data }
        } else {
          this.$message.error(res.data.message || '服务出错，请重试')
        }
      } catch (error) {
        console.log(error)
      }
    },
    close() {
      this.$emit('update:dialogAccountDetailFormVisible', false)
    },
    openDown(url) {
      window.open(url)
    },
    handleQuery(isDeal) {
      this.dateResult = ''
      this.isDeal = isDeal
      this.popVisible = true
      this.btnText =
        isDeal == 2 || isDeal == 3 ? '申请交易明细账单' : '申请账户余额账单'
      this.popTitle =
        isDeal == 2 || isDeal == 3
          ? '账户交易明细' + '（' + this.childForm.accountName + '）'
          : '账户余额账单' + '（' + this.childForm.accountName + '）'
      this.queryList()
    },
    async handleApply() {
      let date1 = '',
        date2 = ''
      if (this.isDeal == 3) {
        date1 = date2 = this.dateResult
      } else {
        date1 = this.dateResult && this.dateResult[0]
        date2 = this.dateResult && this.dateResult[1]
      }

      if (!date1) {
        this.$message.error('请选择时间')
        return
      }
      const end = new Date(date2)
      const begin = new Date(date1)
      const day = this.isDeal === 1 ? 90 : 31
      if (end - begin > day * 24 * 3600000) {
        this.$message.error('时间跨度最大支持' + day + '天')
        return
      }
      const p = {
        accountNo: this.childForm.accountNo,
        isvId: this.childForm.isvId,
        startDate: date1,
        endDate: date2,
        type: this.isDeal, //任务类型：1；日终余额；2：交易明细凭证
      }
      this.applyLoading = true
      try {
        const res = await applyReceiptApi(p)
        this.applyLoading = false
        if (res.status === 200) {
          if (res.data.state === 1) {
            this.$message.success('已申请成功！')
            this.queryList()
          } else if (res.data.state === 3) {
            this.$message.success('申请已受理！')
            this.queryList()
          } else {
            this.$message.error(res.data.returnMsg)
          }
        } else {
          this.$message.error(res.message)
        }
      } catch (error) {
        this.applyLoading = false
        console.log(error)
      }
    },
    closePop() {
      this.popVisible = false
    },
    handleCurrentChange(val) {
      this.pagination.pageNum = val
      this.queryList()
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val
      this.queryList()
    },
    async queryList() {
      try {
        const p = {
          page: {
            current: this.pagination.pageNum,
            size: this.pagination.pageSize,
          },
          accountNo: this.childForm.accountNo,
          isvId: this.childForm.isvId,
          type: this.isDeal, //任务类型：1；日终余额；2：交易明细凭证
        }
        this.loading = true
        const res = await listReceiptApi(p)
        this.loading = false
        if (res.status === 200 && res.data) {
          this.tableData = res.data.items
          if (res.data.total && p.page.current === 1) {
            this.pagination.total = res.data.total
          }
        } else {
          this.$message.error(res.message)
          this.tableData = []
          this.pagination.total = 0
        }
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.el-form-item {
    margin-bottom: 0;
}
.grid-content {
    display: flex;
    align-items: center;
    padding-bottom:16px;
}

.left {
    margin-right: 20px;
    font-size: 14px;
}

.right {
    flex: 1;
}

.btn-content {
    padding-left:50px;
    display: flex;
    justify-content: flex-end;
}
.table-box{border:1px solid #E4E7ED;border-radius:5px;
    .title{border-bottom:1px solid #E4E7ED;font-size:14px;padding:16px 10px;font-weight:500;}
}
</style>
